import React, { useEffect, useState } from "react";
import { collection, getDocs, deleteDoc, doc } from "firebase/firestore";
import { db } from "../../Config/Firebase/config";
import { toast } from "react-toastify";
import EditProduct from "./editProduct";
import ConfirmationModal from "../../Utility/confirmModal";
import "./product.css";
import ProductAdminForm from "./product";

const ProductAdmin = () => {
  const [products, setProducts] = useState([]);
  const [editProduct, setEditProduct] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [action, setAction] = useState(null);
  const [selectedProduct, setSelectedProduct] = useState(null);

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const productCollectionRef = collection(db, "product");
        const data = await getDocs(productCollectionRef);
        const productsData = data.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
        setProducts(productsData);
      } catch (error) {
        console.error("Error fetching products: ", error);
        toast.error("Failed to load products");
      }
    };

    fetchProducts();
  }, []);

  const handleDelete = async (id) => {
    try {
      await deleteDoc(doc(db, "product", id));
      setProducts(products.filter((product) => product.id !== id));
      toast.success("Product deleted successfully");
    } catch (error) {
      console.error("Error deleting product: ", error);
      toast.error("Failed to delete product");
    }
  };

  const handleEdit = (product) => {
    setEditProduct(product);
  };

  const handleConfirm = (e) => {
    e.preventDefault();
    if (action === "delete") {
      handleDelete(selectedProduct.id);
    } else if (action === "edit") {
      handleEdit(selectedProduct);
    }
    setModalOpen(false);
    setSelectedProduct(null);
    setAction(null);
  };

  const openConfirmationModal = (action, product) => {
    setAction(action);
    setSelectedProduct(product);
    setModalOpen(true);
  };

  return (
    <div className="product-admin-container">
      <div className="product-admin-heading">
        <h3>Manage Products</h3>
        <ProductAdminForm />
      </div>
      <div className="product-list">
        {products.map((product) => (
          <div className="product-item" key={product.id}>
            <img src={product.image} alt={product.title} />
            <div className="product-info">
              <h4>{product.title}</h4>
              <p>Price: ${product.price}</p>
              <p>Category: {product.category}</p>
              <div className="product-actions">
                <button onClick={() => openConfirmationModal("edit", product)} className="btn edit-btn">Edit</button>
                <button onClick={() => openConfirmationModal("delete", product)} className="btn delete-btn">Delete</button>
              </div>
            </div>
          </div>
        ))}
      </div>
      {editProduct && <EditProduct product={editProduct} setEditProduct={setEditProduct} setProducts={setProducts} />}
      <ConfirmationModal
        isOpen={modalOpen}
        onClose={() => setModalOpen(false)}
        onConfirm={handleConfirm}
      />
    </div>
  );
};

export default ProductAdmin;
