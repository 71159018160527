import React, { useState, useEffect } from 'react';
import { useDispatch} from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useUserAuth } from "../../context/userAuthContext.jsx";
import { db } from "../../Config/Firebase/config.js";
import { doc, setDoc, getDoc} from 'firebase/firestore';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import "./profile.css";

const Profile = () => {
  const dispatch = useDispatch();
  const { user, logOut } = useUserAuth();
  const navigate = useNavigate();
  const [address, setAddress] = useState({
    city: "Lahan",
    wardNo: "",
    addressDetail: "",
    phoneNumber: "",
    name: ""
  });
  const [isEditing, setIsEditing] = useState(false);

  useEffect(() => {
    if (user) {
      fetchSavedAddress();
    }
  }, [user]);

  const fetchSavedAddress = async () => {
    try {
      const docRef = doc(db, "users", user.uid);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        const userData = docSnap.data();
        if (userData.address) {
          setAddress(userData.address);
          setIsEditing(false);
        } else {
          setIsEditing(true);
        }
      } else {
        setIsEditing(true);
        toast.error("Failed to load address");
      }
    } catch (error) {
      setIsEditing(true);
      toast.error("Failed to load address");
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setAddress((prevAddress) => ({
      ...prevAddress,
      [name]: value,
    }));
  };

  const handleSaveAddress = async () => {
    if (address.wardNo && address.addressDetail && address.phoneNumber && address.name) {
      const userRef = doc(db, "users", user.uid);
      await setDoc(userRef, { address: address }, { merge: true });

      toast.success("Address saved successfully");
      setIsEditing(false);
    } else {
      toast.error("Please fill all address fields");
    }
  };

  const handleEditAddress = () => {
    setIsEditing(true);
  };

  const handleDeleteAddress = async () => {
    const userRef = doc(db, "users", user.uid);
    await setDoc(userRef, { address: {} }, { merge: true });
    setAddress({
      city: "Lahan",
      wardNo: "",
      addressDetail: "",
      phoneNumber: "",
      name: ""
    });
    setIsEditing(true);
    toast.success("Address deleted successfully");
  };

  const handleCancelEdit = () => {
    fetchSavedAddress();
  };

  const handleLogout = async () => {
    try {
      await logOut();
      navigate("/");
      toast.success("Logged out successfully");
    } catch (error) {
        console.log(error);
      toast.error("Failed to logout");
    }
  };

  if (!user) {
    navigate("/");
    return null;
  }

  return (
    <section className="profile section" id="profile">
      <ToastContainer />
      <div className="container">
        <div className="row">
          <div className="section-title padd-15">
            <h2>Profile</h2>
          </div>
        </div>
        <div className="row profile-row">
          <div className="profile-details">
            <h3>Profile Details</h3>
            {user.photoURL && <img src={user.photoURL} alt="Profile" className="profile-image" />}
            <p className="username">{user.displayName || "N/A"}</p>
            <p>{user.email}</p>
            <button className="logout-button" onClick={handleLogout}>
              Logout
            </button>
          </div>
          <div className="address-form">
            <h3>Address Details</h3>
            <label>
              {isEditing ? <span style={{"color":"var(--skin-color-3)"}}>Available only In Lahan: Not Editable</span> : "City:"}
              <input type="text" name="city" value="Lahan" readOnly />
            </label>
            <label>
              {isEditing ? <span style={{"color":"var(--skin-color-2)"}}>Edit Ward No:</span> : "Ward No:"}
             
              <input
                type="text"
                name="wardNo"
                value={address.wardNo}
                onChange={handleInputChange}
                disabled={!isEditing}
              />
            </label>
            <label>
              {isEditing ? <span style={{"color":"var(--skin-color-2)"}}>Edit Area Detail:</span> : "Area Detail:"}
             
              <input
                type="text"
                name="addressDetail"
                value={address.addressDetail}
                onChange={handleInputChange}
                disabled={!isEditing}
              />
            </label>
            <label>
              {isEditing ? <span style={{"color":"var(--skin-color-2)"}}>Edit Phone Number:</span> : "Phone Number:"}
              
              <input
                type="text"
                name="phoneNumber"
                value={address.phoneNumber}
                onChange={handleInputChange}
                disabled={!isEditing}
              />
            </label>
            <label>
              {isEditing ? <span style={{"color":"var(--skin-color-2)"}}>Edit Name:</span> : "Name:"}
              
              <input
                type="text"
                name="name"
                value={address.name}
                onChange={handleInputChange}
                disabled={!isEditing}
              />
            </label>
            <button
              className="save-address-button"
              onClick={isEditing ? handleSaveAddress : handleEditAddress}
              style={{
                backgroundColor: isEditing ? "var(--skin-color-3)" : "var(--skin-color-2)"
              }}
            >
              {address.name ? isEditing ? "Save Address" : "Change Address" : "Add New Address"}
            </button>
            {isEditing && (
              <button
                className="edit-address-button"
                onClick={handleCancelEdit}
                style={{ backgroundColor: "var(--skin-color-1)" }}
              >
                Cancel Edit
              </button>
            )}
            {!isEditing && (
              <button
                className="delete-address-button"
                onClick={handleDeleteAddress}
                style={{ backgroundColor: "var(--skin-color-4)" }}
              >
                Delete Address
              </button>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Profile;
