import React from 'react';
import './comingSoon.css';

const ComingSoon = () => {
  return (
    <div className="coming-soon-container">
      <div className="circle">
        <div className="text">Coming Soon</div>
        <div className="rotating-arcs">
          <div className="arc"></div>
          <div className="arc"></div>
          <div className="arc"></div>
        </div>
      </div>
    </div>
  );
};

export default ComingSoon;
