import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { removeFromCart, updateQuantity } from "./cartSlice";
import "./cart.css";
import { useUserAuth } from "../../context/userAuthContext.jsx";
import { useNavigate } from "react-router-dom";
import Loading from "../../Utility/loading.jsx";
import EmptyCart from "../emptyCart/emptyCart.jsx";

const Cart = () => {
  const { user } = useUserAuth();
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const cartItems = useSelector((state) => state.cart.items);

  useEffect(() => {
    const loadingTimer = setTimeout(() => {
      setLoading(false);
      if (!user) {
        navigate("/");
      }
    }, 2000);
    return () => clearTimeout(loadingTimer);
  }, [user, navigate]);

  if (loading) {
    return <Loading message="Authenticating User..." />;
  }

  const calculateItemTotal = (price, quantity) => {
    return (price * quantity).toFixed(2);
  };

  const calculateTotalPrice = () => {
    return cartItems.reduce((total, item) => total + item.price * item.quantity, 0).toFixed(2);
  };

  const handleRemoveFromCart = (itemId) => {
    if (user) {
      dispatch(removeFromCart({ itemId, userId: user.uid }));
    } else {
      navigate("/");
    }
  };

  const handleUpdateCartItem = (itemId, quantity) => {
    if (quantity < 1) return;
    if (user) {
      dispatch(updateQuantity({ itemId, quantity, userId: user.uid }));
    } else {
      navigate("/");
    }
  };

  const handleQuantityChange = (e, itemId) => {
    const value = e.target.value;
    const quantity = value ? parseInt(value, 10) : 1;
    if (!isNaN(quantity) && quantity > 0) {
      handleUpdateCartItem(itemId, quantity);
    }
  };

  const handleCheckout = () => {
    if (user) {
      navigate("/checkout");
    } else {
      navigate("/");
    }
  };

  if (!user) {
    navigate("/");
    return null;
  }

  if (cartItems.length === 0) {
    return <EmptyCart />;
  }

  return (
    <section className="cart section" id="cart">
      <div className="container">
        <div className="row">
          <div className="section-title padd-15">
            <h2>Your Cart</h2>
          </div>
        </div>
        <div className="row cart-row">
          {cartItems.map((item) => (
            <div className="cart-item" key={item.id}>
              <img src={item.image} alt={item.title} />
              <div className="item-details">
                <h3>{item.title}</h3>
                <p>Price: Rs {item.price}</p>
                <p>Total: Rs {calculateItemTotal(item.price, item.quantity)}</p>
                <div className="quantity">
                  <button onClick={() => handleUpdateCartItem(item.id, item.quantity - 1)}>-</button>
                  <input
                    type="number"
                    value={item.quantity}
                    onChange={(e) => handleQuantityChange(e, item.id)}
                    min="1"
                  />
                  <button onClick={() => handleUpdateCartItem(item.id, item.quantity + 1)}>+</button>
                </div>
                <button className="remove-item" onClick={() => handleRemoveFromCart(item.id)}>
                  X
                </button>
              </div>
            </div>
          ))}
          <div className="cart-checkout">
            <h3>Total Amount: Rs {calculateTotalPrice()}</h3>
            <button className="cart-checkout-button" onClick={handleCheckout}>
              Checkout
            </button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Cart;
