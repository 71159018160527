import React, { useState, useEffect } from "react";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import "./product.css";
import { addDoc, collection, getDocs, serverTimestamp } from "firebase/firestore";
import { toast } from "react-toastify";
import { getCurrentDate, handleImageChange } from "../../Utility/utils";
import { db, storage } from "../../Config/Firebase/config";

const ProductAdminForm = () => {
  const [title, setTitle] = useState("");
  const [file, setFile] = useState(null);
  const [price, setPrice] = useState("");
  const [costPrice, setCostPrice] = useState("");
  const [available, setAvailable] = useState(true);
  const [category, setCategory] = useState("");
  const [categories, setCategories] = useState([]);
  const [formKey, setFormKey] = useState(0); // Key to force reset form

  useEffect(() => {
    const CACHE_KEY = "categoryCache";
    const CACHE_EXPIRATION = 7 * 24 * 60 * 60 * 1000;

    const fetchCategories = async () => {
      try {
        const categoryCollectionRef = collection(db, "category");
        const data = await getDocs(categoryCollectionRef);
        const categoriesData = data.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        setCategories(categoriesData);
        const currentTime = new Date().getTime();
        const cachedData = {
          data: categoriesData,
          timestamp: currentTime,
        };
        localStorage.setItem(CACHE_KEY, JSON.stringify(cachedData));
      } catch (error) {
        console.error("Error fetching categories: ", error);
      }
    };

    const cachedData = localStorage.getItem(CACHE_KEY);
    if (cachedData) {
      const { data, timestamp } = JSON.parse(cachedData);
      const currentTime = new Date().getTime();
      if (currentTime - timestamp <= CACHE_EXPIRATION) {
        setCategories(data);
      } else {
        fetchCategories();
      }
    } else {
      fetchCategories();
    }
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (title.trim() !== "" && file !== null && category.trim() !== "" && price.trim() !== "" && costPrice.trim() !== "") {
      const filesFolderRef = ref(storage, `productImages/${file.name}`);
      try {
        await uploadBytes(filesFolderRef, file);
        const downloadURL = await getDownloadURL(filesFolderRef);

        const productRef = collection(db, "product");

        await addDoc(productRef, {
          title,
          image: downloadURL,
          price,
          costPrice,
          available,
          category,
          createdAt: serverTimestamp(),
          updatedAt: serverTimestamp(),
        });

        toast.success("Product added successfully!");
        setTitle("");
        setFile(null);
        setPrice("");
        setCostPrice("");
        setAvailable(true);
        setCategory("");
        setFormKey(formKey + 1);
      } catch (error) {
        toast.error("Something went wrong");
      }
    }
  };

  const onImageChange = (event) => {
    handleImageChange(event, setFile);
  };

  return (
    <div className="product-admin-container">
      <div className="product-admin-heading">
        <h3>Add Product</h3>
      </div>
      <div className="product-admin-form">
        <form onSubmit={handleSubmit} key={formKey}>
          <div className="form-group">
            <label htmlFor="category">Category:</label>
            <select
              id="category"
              value={category}
              onChange={(e) => setCategory(e.target.value)}
              required
            >
              <option value="">Select Category</option>
              {categories.map((cat) => (
                <option key={cat.id} value={cat.title}>
                  {cat.title}
                </option>
              ))}
            </select>
          </div>
          <div className="form-group">
            <label htmlFor="title">Title:</label>
            <input
              type="text"
              id="title"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="price">Price:</label>
            <input
              type="string"
              id="price"
              value={price}
              onChange={(e) => setPrice(e.target.value)}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="costPrice">Cost Price:</label>
            <input
              type="string"
              id="costPrice"
              value={costPrice}
              onChange={(e) => setCostPrice(e.target.value)}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="file">Choose Image:</label>
            <input
              type="file"
              id="file"
              accept="image/*"
              onChange={onImageChange}
              required
            />
          </div>
          <button className="btn" type="submit">
            Submit
          </button>
        </form>
      </div>
    </div>
  );
};

export default ProductAdminForm;
