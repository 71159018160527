import React, { useState, useEffect } from "react";
import { collection, getDocs, orderBy, query } from "firebase/firestore";
import { db } from "../../Config/Firebase/config";
import "./carousel.css";  // Import your carousel specific CSS

const Carousel = () => {
  const [sliderImages, setSliderImages] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const CACHE_KEY = "carouselCache";
    const CACHE_EXPIRATION = 7 * 24 * 60 * 60 * 1000; // 7 days in milliseconds
    const carouselCollectionRef = collection(db, "carousel");

    const fetchCarouselImages = async () => {
      try {
        const q = query(carouselCollectionRef);
        const data = await getDocs(q);
        const images = data.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));
        setSliderImages(images);

        const currentTime = new Date().getTime();
        const cachedData = {
          data: images,
          timestamp: currentTime,
        };
        localStorage.setItem(CACHE_KEY, JSON.stringify(cachedData));
      } catch (error) {
        console.error("Error fetching carousel images: ", error);
      }
    };

    const cachedData = localStorage.getItem(CACHE_KEY);
    if (cachedData) {
      const { data, timestamp } = JSON.parse(cachedData);
      const currentTime = new Date().getTime();
      if (currentTime - timestamp <= CACHE_EXPIRATION) {
        setSliderImages(data);
      } else {
        fetchCarouselImages();
      }
    } else {
      fetchCarouselImages();
    }
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex(prevIndex => (prevIndex + 1) % sliderImages.length);
    }, 5000); // Change image every 5 seconds

    return () => clearInterval(interval);
  }, [sliderImages]);

  if (sliderImages.length === 0) {
    return <div>Loading...</div>;
  }

  return (
    <div className="slider">
      {sliderImages.map((image, index) => (
        <div
          key={image.id}
          className={`slider-slide ${index === currentIndex ? 'active' : ''}`}
        >
          <img src={image.image} alt={image.title} />
          <div className="slider-caption">{image.title}</div>
        </div>
      ))}
    </div>
  );
};

export default Carousel;
