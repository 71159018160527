import React from 'react';
import { useNavigate } from 'react-router-dom';
import iconData from './icon';
import './emptyCart.css';

const EmptyCart = () => {
  const navigate = useNavigate();

  const handleAddProduct = () => {
    navigate('/');
  };

  return (
    <div className="empty-cart-container">
      <div className="icon-wrapper">
        {iconData[0].icon}
      </div>
      <h2>Your Cart is Empty</h2>
      <p>Looks like you haven't added anything to your cart yet.</p>
      <button className="add-product-button" onClick={handleAddProduct}>
        Add Product
      </button>
    </div>
  );
};

export default EmptyCart;
