import React, { useState, useEffect, useCallback } from "react";
import { collection, getDocs, query, where, orderBy, startAfter, limit } from "firebase/firestore";
import { db } from "../../Config/Firebase/config";
import { useParams, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "./product.css";
import { useDispatch, useSelector } from "react-redux";
import { addToCart } from "../Cart/cartSlice";
import { useUserAuth } from "../../context/userAuthContext";
import iconData from './icon';
import ComingSoon from "../../Utility/comingSoon";

const CategoryProducts = () => {
  const { category } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user, googleSignIn } = useUserAuth();
  const [products, setProducts] = useState([]);
  const [lastDoc, setLastDoc] = useState(null);
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const cartItems = useSelector((state) => state.cart.items);

  const fetchInitialProducts = useCallback(async () => {
    setLoading(true);
    try {
      const q = query(
        collection(db, "product"),
        where("category", "==", category),
        orderBy("createdAt", "desc"),
        limit(4)
      );
      const documentSnapshots = await getDocs(q);
      const lastVisible = documentSnapshots.docs[documentSnapshots.docs.length - 1];
      setLastDoc(lastVisible);
      const productsData = documentSnapshots.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
      setProducts(productsData);
      setHasMore(true); // Reset hasMore when fetching initial products
    } catch (error) {
      toast.error("Failed to load products");
    } finally {
      setLoading(false);
    }
  }, [category]);

  const fetchMoreProducts = useCallback(async () => {
    if (!hasMore || loading) return;
    setLoading(true);
    try {
      const q = query(
        collection(db, "product"),
        where("category", "==", category),
        orderBy("createdAt", "desc"),
        startAfter(lastDoc),
        limit(2)
      );
      const documentSnapshots = await getDocs(q);
      const lastVisible = documentSnapshots.docs[documentSnapshots.docs.length - 1];
      if (!lastVisible || documentSnapshots.docs.length === 0) {
        setHasMore(false);
      } else {
        setLastDoc(lastVisible);
        const productsData = documentSnapshots.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
        setProducts((prevProducts) => [...prevProducts, ...productsData]);
      }
    } catch (error) {
      toast.error("Failed to load more products");
    } finally {
      setLoading(false);
    }
  }, [hasMore, lastDoc, loading]);

  useEffect(() => {
    fetchInitialProducts();
    window.scrollTo(0, 0); // Reset scroll position to top when component mounts
  }, [fetchInitialProducts]);

  useEffect(() => {
    const handleScroll = () => {
      if (window.innerHeight + document.documentElement.scrollTop + 1 >= document.documentElement.scrollHeight) {
        fetchMoreProducts();
      }
    };

    const debounce = (func, wait) => {
      let timeout;
      return (...args) => {
        clearTimeout(timeout);
        timeout = setTimeout(() => func.apply(this, args), wait);
      };
    };

    const debouncedHandleScroll = debounce(handleScroll, 100);

    window.addEventListener("scroll", debouncedHandleScroll);
    return () => window.removeEventListener("scroll", debouncedHandleScroll);
  }, [fetchMoreProducts]);

  const handleAddToCart = async (product) => {
  if (!user) {
    try {
      const signedInUser = await googleSignIn();
      if (signedInUser) {
        dispatch(addToCart({ product, userId: signedInUser.uid }));
        toast.success(`${product.title} added to cart`);
      }
    } catch (error) {
      toast.error("Failed to sign in");
    }
  } else {
    dispatch(addToCart({ product, userId: user.uid }));
    toast.success(`${product.title} added to cart`);
  }
};



  const handleViewInCart = () => {
    navigate("/cart");
  };

  const isInCart = (productId) => {
    return cartItems.some((item) => item.id === productId);
  };

  return (
    <section className="product section" id="product">
      <div className="container">
        <div className="row">
          <div className="section-title padd-15">
            <h2>Products in {category}</h2>
          </div>
        </div>
        {products.length !== 0 ?<div className="row product-row">
          {products.map((product) => (
            <div className="product-card" key={product.id}>
              <img src={product.image} alt={product.title} />
              <h3>{product.title}</h3>
              <p>Rs {product.price}</p>
              {isInCart(product.id) ? (
                <button className="view-cart" onClick={handleViewInCart}>
                  <span>View in Cart</span>
                  <i className="icon">{iconData[0].icon}</i>
                </button>
              ) : (
                <button className="add-to-cart" onClick={() => handleAddToCart(product)}>
                  Add To Cart
                </button>
              )}
            </div>
          ))}
        </div> : <ComingSoon />}
      </div>
      {loading && <p>Loading...</p>}
    </section>
  );
};

export default CategoryProducts;
