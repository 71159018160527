import React, { useEffect, useState } from "react";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../../Config/Firebase/config";
import { useNavigate } from "react-router-dom";
import "./category.css";

const Category = () => {
  const [categories, setCategories] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const CACHE_KEY = "categoryCache";
    const CACHE_EXPIRATION = 7 * 24 * 60 * 60 * 1000; // 7 days in milliseconds
    const categoryCollectionRef = collection(db, "category");

    const fetchCategories = async () => {
      try {
        const data = await getDocs(categoryCollectionRef);
        const categoriesData = data.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setCategories(categoriesData);

        const currentTime = new Date().getTime();
        const cachedData = {
          data: categoriesData,
          timestamp: currentTime,
        };
        localStorage.setItem(CACHE_KEY, JSON.stringify(cachedData));
      } catch (error) {
        console.error("Error fetching category data: ", error);
      }
    };

    const cachedData = localStorage.getItem(CACHE_KEY);
    if (cachedData) {
      const { data, timestamp } = JSON.parse(cachedData);
      const currentTime = new Date().getTime();
      if (currentTime - timestamp <= CACHE_EXPIRATION) {
        setCategories(data);
      } else {
        fetchCategories();
      }
    } else {
      fetchCategories();
    }
  }, []);

  const handleCategoryClick = (category) => {
    navigate(`/category/${category}`);
  };

  return (
    <section className="category section" id="category">
      <div className="container">
        <div className="row">
          <div className="section-title padd-15">
            <h2>Category</h2>
          </div>
        </div>
        <div className="row">
          {categories.map((data) => (
            <div
              className="category-info-item category-item"
              key={data.id}
              onClick={() => handleCategoryClick(data.title)}
            >
              <div className="category-image">
                <img src={data.image} alt={data.title} />
              </div>
              <h4>{data.title}</h4>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Category;
