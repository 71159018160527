import { initializeApp } from "firebase/app";
import {getFirestore} from "firebase/firestore";
import {getStorage} from "firebase/storage";
import {getAuth, GoogleAuthProvider} from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyCixHt3ivrVpterJVRYzgAbQjSYh_0RooQ",
  authDomain: "ltsshop-1a325.firebaseapp.com",
  projectId: "ltsshop-1a325",
  storageBucket: "ltsshop-1a325.appspot.com",
  messagingSenderId: "254762868931",
  appId: "1:254762868931:web:73149e4b6c62896ab24276"
};

const app = initializeApp(firebaseConfig);

export const db = getFirestore(app);
export const storage = getStorage(app);
export const auth = getAuth(app);
export const googleAuthProvider = new GoogleAuthProvider();