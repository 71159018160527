import React from 'react';
import { useNavigate } from 'react-router-dom';
import './order.css';
import orderIcon from './orderIcon';

const EmptyOrder = () => {
  const navigate = useNavigate();

  const handleBuyNowClick = () => {
    navigate('/');
  };

  return (
    <div className="empty-order-container">
      <div className="empty-order-icon">
        {orderIcon[0].icon}
      </div>
      <h2>Your Order is Empty</h2>
      <p>Looks like you haven't ordered anything yet.</p>
      <button className="buy-now-button" onClick={handleBuyNowClick}>
        Buy Now
      </button>
    </div>
  );
};

export default EmptyOrder;
