import React from "react";
import Contact from "../../Components/Contact/contact";
import Carousel from "../../Components/Slider/carousel";
import Category from "../../Components/Category/category";
import Product from "../../Components/Product/product";

const Home = () => {
  return (
    <>
      <div className="main-content">
        <Carousel />
        <Product />
      </div>
    </>
  );
};

export default Home;
