import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useUserAuth } from "../../context/userAuthContext.jsx";
import { db } from "../../Config/Firebase/config.js";
import { collection, getDocs } from "firebase/firestore";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import "./order.css";
import Loading from "../../Utility/loading.jsx";
import EmptyOrder from "../EmptyOrder/order.jsx";

const OrderPage = () => {
    const { user } = useUserAuth();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [orders, setOrders] = useState([]);
    const [statusFilter, setStatusFilter] = useState("pending");
    const [totalOrderQuantity, setTotalOrderQuantity] = useState(0);
    const [totalOrderAmount, setTotalOrderAmount] = useState(0);
    const [rewardAmount, setRewardAmount] = useState(0);

    const calculateStatistics = (userOrders) => {
        const filteredOrders = userOrders.filter(order => order.status === "delivered");
        const totalQuantity = filteredOrders.reduce((total, order) => total + order.items.reduce((sum, item) => sum + item.quantity, 0), 0);
        const totalAmount = filteredOrders.reduce((total, order) => total + parseFloat(order.totalAmount), 0);
        const reward = (totalAmount * 0.02).toFixed(2);

        setTotalOrderQuantity(totalQuantity);
        setTotalOrderAmount(totalAmount.toFixed(2));
        setRewardAmount(reward);
    };

    const fetchOrders = async () => {
        try {
            const ordersRef = collection(db, "orders");
            const ordersSnapshot = await getDocs(ordersRef);
            const userOrders = ordersSnapshot.docs
                .filter(doc => doc.data().userId === user.uid)
                .map(doc => ({ id: doc.id, ...doc.data() }));
            setOrders(userOrders);
            calculateStatistics(userOrders);
        } catch (error) {
            console.log(error);
            toast.error("Failed to load orders");
        }
    };
    
        useEffect(() => {
        const loadingTimer = setTimeout(() => {
        setLoading(false);
        if (!user) {
            navigate("/");
        } else {
            fetchOrders()
        }
        }, 2000);
        return () => clearTimeout(loadingTimer); // Cleanup the timer if the component unmounts.
    }, [user, navigate]);

    if (loading) {
        return <Loading message="Authenticating User..."/>
    }

    const getOrderCountByStatus = (status) => {
        return orders.filter(order => order.status === status).length;
    };

    const filteredOrders = orders.filter(order => order.status === statusFilter);

    if(orders.length === 0){
        return <EmptyOrder />
    }


    return (
        <section className="order-page section" id="order-page">
            <ToastContainer />
            <div className="container">
                <div className="row">
                    <div className="section-title padd-15">
                        <h2>My Orders</h2>
                    </div>
                </div>
                <div className="row status-buttons">
                    {["pending", "processed", "packed", "on the way", "delivered"].map(status => (
                        <button
                            key={status}
                            onClick={() => setStatusFilter(status)}
                            className={statusFilter === status ? "active" : ""}
                        >
                            {status.charAt(0).toUpperCase() + status.slice(1)} ({getOrderCountByStatus(status)})
                        </button>
                    ))}
                </div>
                {statusFilter === "delivered" && (
                    <div className="row order-summary-row">
                        <div className="order-summary">
                            <h3>Order Statistics</h3>
                            <p>Total Order Quantity: {totalOrderQuantity}</p>
                            <p>Total Order Amount: Rs {totalOrderAmount}</p>
                            <p>Reward Amount: Rs {rewardAmount}</p>
                        </div>
                    </div>
                )}
                <div className="row orders-row">
                    {filteredOrders.map((order) => (
                        <div className="order-item" key={order.id}>
                            <h4>Order ID: {order.orderId}</h4>
                            <p>Order Date: {new Date(order.orderDate.seconds * 1000).toLocaleDateString()}</p>
                            <p>Status: {order.status}</p>
                            <p>Total Amount: Rs {order.totalAmount}</p>
                            <h5>Items:</h5>
                            {order.items.map((item, index) => (
                                <div key={index} className='items'>
                                    <img src={item.image} alt={item.title} />
                                    <p>{item.title}</p>
                                    <p>Quantity: {item.quantity}</p>
                                    <p>Price: Rs {item.price}</p>
                                </div>
                            ))}
                        </div>
                    ))}
                </div>
            </div>
        </section>
    );
};

export default OrderPage;
