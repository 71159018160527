import React, { useEffect, useState } from "react";
import { HashLink } from "react-router-hash-link";
import { useNavigate } from "react-router-dom";
import "./header.css";
import { useUserAuth } from "../../context/userAuthContext";
import { useSelector } from "react-redux"; 
import logo_dark from '../../Data/logo_dark.png';
import logo_light from '../../Data/logo_light.png';
import iconData from './icon';

const Header = ({ day, changeMode }) => {
  const { user, googleSignIn } = useUserAuth();
  const [hasShadow, setHasShadow] = useState(false);
  const [showMenu, setShowMenu] = useState(false);
  const navigate = useNavigate();

  const cartItems = useSelector((state) => state.cart.items);
  //this below code show total quantity of item in cart
  // const totalItems = cartItems.reduce((total, item) => total + item.quantity, 0);
  const totalItems = cartItems.length;

  const toggleMenu = () => {
    setShowMenu(!showMenu);
  };

  const handleLinkClick = () => {
    setShowMenu(false);
    window.scrollTo(0, 0);
  };

  const handleProfileClick = () => {
    handleLinkClick();
    navigate("/profile");
  };

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setHasShadow(true);
        setShowMenu(false);
      } else {
        setHasShadow(false);
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div className={`HeaderContainer ${hasShadow ? "shadow-header" : ""}`}>
      <div className="HeaderContent">
        <div className="HeaderLogo">
          <HashLink className="nav-link active" smooth to="/#" onClick={handleLinkClick}>
            <img src={day ? logo_light : logo_dark} alt='logo' />
          </HashLink>
        </div>
        <div className="HeaderNavLinks">
          <div className="HeaderLink">
            <HashLink smooth to='/' className='nav-link' onClick={handleLinkClick}>
              Product
            </HashLink>
          </div>
          <div className="HeaderLink">
            <HashLink smooth to='/category' className='nav-link' onClick={handleLinkClick}>
              Category
            </HashLink>
          </div>
          <div className="HeaderLink">
            <HashLink smooth to='/cart' className='nav-link' onClick={handleLinkClick}>
              Cart
              <i className="icon">{iconData[0].icon}</i>
              {totalItems > 0 && <span className="cart-count">{totalItems}</span>}
            </HashLink>
          </div>
          <div className="HeaderLink">
            <HashLink smooth to='/orders' className='nav-link' onClick={handleLinkClick}>
              Order
            </HashLink>
          </div>
          <div className="HeaderLink">
            <HashLink smooth to='/contact' className='nav-link' onClick={handleLinkClick}>
              Contact
            </HashLink>
          </div>
          <div className="HeaderLink">
            {user ? (
              <button className="btn" onClick={handleProfileClick}>
                Profile
              </button>
            ) : (
              <button className="btn" onClick={googleSignIn}>
                Signin
              </button>
            )}
          </div>
        </div>
        <div className="HamburgerButton" onClick={toggleMenu}>
          {showMenu ? "✕" : "☰"}
        </div>
      </div>
      <div className={`MobileMenu ${showMenu ? "show" : ""}`}>
        <div className="HeaderLink">
          <HashLink smooth to='/' className='nav-link' onClick={handleLinkClick}>
            Product
          </HashLink>
        </div>
        <div className="HeaderLink">
          <HashLink smooth to='/category' className='nav-link' onClick={handleLinkClick}>
            Category
          </HashLink>
        </div>
        <div className="HeaderLink">
          <HashLink smooth to='/cart' className='nav-link' onClick={handleLinkClick}>
            Cart
            <i className="icon">{iconData[0].icon}</i>
            {totalItems > 0 && <span className="cart-count">{totalItems}</span>}
          </HashLink>
        </div>
        <div className="HeaderLink">
          <HashLink smooth to='/orders' className='nav-link' onClick={handleLinkClick}>
            Order
          </HashLink>
        </div>
        <div className="HeaderLink">
          <HashLink smooth to='/contact' className='nav-link' onClick={handleLinkClick}>
            Contact
          </HashLink>
        </div>
        <div className="HeaderLink">
          {user ? (
            <button className="btn" onClick={handleProfileClick}>
              Profile
            </button>
          ) : (
            <button className="btn" onClick={googleSignIn}>
              Signin
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default Header;
