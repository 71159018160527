import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useUserAuth } from "../../context/userAuthContext.jsx";
import { db } from "../../Config/Firebase/config.js";
import { doc, setDoc, getDoc, addDoc, collection, getDocs, deleteDoc, updateDoc, arrayUnion } from "firebase/firestore";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import "./checkout.css";
import { setCartItems } from "../Cart/cartSlice.js";
import EmptyCart from "../emptyCart/emptyCart.jsx";

const Checkout = () => {
    const dispatch = useDispatch();
  const { user } = useUserAuth();
  const navigate = useNavigate();
  const cartItems = useSelector((state) => state.cart.items);
  const [address, setAddress] = useState({
    city: "Lahan",
    wardNo: "",
    addressDetail: "",
    phoneNumber: "",
    name: ""
  });
  const [isEditing, setIsEditing] = useState(false);

  useEffect(() => {
    if (user) {
      fetchSavedAddress();
    }
  }, [user]);

  const fetchSavedAddress = async () => {
    try {
      const docRef = doc(db, "users", user.uid);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        const userData = docSnap.data();
        if (userData.address) {
          setAddress(userData.address);
          setIsEditing(false);
        } else {
          setIsEditing(true);
        }
      } else {
        setIsEditing(true);
        toast.error("Failed to load address");
      }
    } catch (error) {
      setIsEditing(true);
      toast.error("Failed to load address");
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setAddress((prevAddress) => ({
      ...prevAddress,
      [name]: value,
    }));
  };

  const handleSaveAddress = async () => {
    if (address.wardNo && address.addressDetail && address.phoneNumber && address.name) {
      const userRef = doc(db, "users", user.uid);
      await setDoc(userRef, { address: address }, { merge: true });

      toast.success("Address saved successfully");
      setIsEditing(false);
    } else {
      toast.error("Please fill all address fields");
    }
  };

  const handleEditAddress = () => {
    setIsEditing(true);
  };

  const handleCancelEdit = () => {
    fetchSavedAddress();
  };

  const handlePlaceOrder = async () => {
    if (!user) {
      navigate("/");
      return;
    }

    if (!address.wardNo || !address.addressDetail || !address.phoneNumber || !address.name) {
      toast.error("Please save the address before placing an order");
      return;
    }

    const orderId = generateOrderId();
    const orderData = {
      orderId,
      address: address,
      items: cartItems,
      totalAmount: calculateTotalPrice(),
      userId: user.uid,
      orderDate: new Date(),
      status: "pending"
    };

    await addDoc(collection(db, "orders"), orderData);

    // Add the orderId to the user's document
        const userRef = doc(db, "users", user.uid);
        await updateDoc(userRef, {
            orderIds: arrayUnion(orderId)
        });

    // Delete the cart data from the database after placing the order
    const cartRef = collection(db, "users", user.uid, "cart");
    const cartSnapshot = await getDocs(cartRef);
    cartSnapshot.forEach(async (doc) => {
      await deleteDoc(doc.ref);
    });

    dispatch(setCartItems([])); // Add this line


    toast.success("Order placed successfully");
    navigate("/orders");
  };

  const generateOrderId = () => {
    return `ORD-${Date.now()}`;
  };

  const calculateItemTotal = (price, quantity) => {
    return (price * quantity).toFixed(2);
  };

  const calculateTotalPrice = () => {
    return cartItems.reduce((total, item) => total + item.price * item.quantity, 0).toFixed(2);
  };

  if (!user) {
    navigate("/");
    return null;
  }

  if(cartItems.length === 0){
    return <EmptyCart />
  }

  return (
    <section className="checkout section" id="checkout">
      <ToastContainer />
      <div className="container">
        <div className="row">
          <div className="section-title padd-15">
            <h2>Checkout</h2>
          </div>
        </div>
        <div className="row checkout-row">
          <div className="address-form">
            <h3>Address Details</h3>
            <label>
              {isEditing ? <span style={{"color":"var(--skin-color-3)"}}>Available only In Lahan: Not Editable</span> : "City:"}
              <input type="text" name="city" value="Lahan" readOnly />
            </label>
            <label>
              {isEditing ? <span style={{"color":"var(--skin-color-2)"}}>Edit Ward No:</span> : "Ward No:"}
              <input
                type="text"
                name="wardNo"
                value={address.wardNo}
                onChange={handleInputChange}
                disabled={!isEditing}
              />
            </label>
            <label>
              {isEditing ? <span style={{"color":"var(--skin-color-2)"}}>Edit Area Detail:</span> : "Area Detail:"}
              <input
                type="text"
                name="addressDetail"
                value={address.addressDetail}
                onChange={handleInputChange}
                disabled={!isEditing}
              />
            </label>
            <label>
              {isEditing ? <span style={{"color":"var(--skin-color-2)"}}>Edit Phone Number:</span> : "Phone Number:"}
              
              <input
                type="text"
                name="phoneNumber"
                value={address.phoneNumber}
                onChange={handleInputChange}
                disabled={!isEditing}
              />
            </label>
            <label>
              {isEditing ? <span style={{"color":"var(--skin-color-2)"}}>Edit Name:</span> : "Name:"}
              <input
                type="text"
                name="name"
                value={address.name}
                onChange={handleInputChange}
                disabled={!isEditing}
              />
            </label>
            <button
              className="save-address-button"
              onClick={isEditing ? handleSaveAddress : handleEditAddress}
              style={{
                backgroundColor: isEditing ? "var(--skin-color-3)" : "var(--skin-color-2)"
              }}
            >
              {address.name ? isEditing ? "Save Address" : "Change Delivery Address" : "Add New Address"}
            </button>
            {isEditing && (
              <button
                className="edit-address-button"
                onClick={handleCancelEdit}
                style={{ backgroundColor: "var(--skin-color-1)" }}
              >
                Cancel Edit
              </button>
            )}
          </div>
          <div className="checkout-cart-summary">
            <h3>Cart Summary</h3>
            {cartItems.map((item) => (
              <div className="checkout-cart-item" key={item.id}>
                <h4>{item.title}</h4>
                <p>Quantity: {item.quantity}</p>
                <p>Price: Rs {item.price}</p>
                <p>Total: Rs {calculateItemTotal(item.price, item.quantity)}</p>
              </div>
            ))}
            <div className="total-amount">
              <h3>Total Amount: Rs {calculateTotalPrice()}</h3>
            </div>
            <button className="place-order-button" onClick={handlePlaceOrder}>
              Place Order
            </button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Checkout;
