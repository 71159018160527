import { createSlice } from "@reduxjs/toolkit";
import { db } from "../../Config/Firebase/config";
import { doc, setDoc, deleteDoc, updateDoc } from "firebase/firestore";

const initialState = {
  items: [],
};

const cartSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    setCartItems: (state, action) => {
      state.items = action.payload;
    },
    addToCart: (state, action) => {
      const { product, userId } = action.payload;
      if (!userId) {
        console.error("User is not authenticated.");
        return;
      }
      const existingItem = state.items.find((cartItem) => cartItem.id === product.id && cartItem.userId === userId);
      if (existingItem) {
        existingItem.quantity += 1;
        updateCartItemInFirestore(existingItem, userId);
      } else {
        const newItem = { ...product, quantity: 1, userId };
        state.items.push(newItem);
        addCartItemToFirestore(newItem, userId);
      }
    },
    removeFromCart: (state, action) => {
      const { itemId, userId } = action.payload;
      if (!userId) {
        console.error("User is not authenticated.");
        return;
      }
      const index = state.items.findIndex((cartItem) => cartItem.id === itemId && cartItem.userId === userId);
      if (index !== -1) {
        removeCartItemFromFirestore(state.items[index].id, userId);
        state.items.splice(index, 1);
      }
    },
    updateQuantity: (state, action) => {
      const { itemId, quantity, userId } = action.payload;
      if (!userId) {
        console.error("User is not authenticated.");
        return;
      }
      if (quantity < 1) return; // Prevent quantity from going below 1
      const existingItem = state.items.find((cartItem) => cartItem.id === itemId && cartItem.userId === userId);
      if (existingItem) {
        existingItem.quantity = quantity;
        updateCartItemInFirestore(existingItem, userId);
      }
    },
    clearCart: (state) => {
      state.items = [];
    },
  },
});

const addCartItemToFirestore = async (item, userId) => {
  try {
    await setDoc(doc(db, "users", userId, "cart", item.id), item);
  } catch (error) {
    console.error("Error adding item to cart: ", error);
  }
};

const updateCartItemInFirestore = async (item, userId) => {
  try {
    await updateDoc(doc(db, "users", userId, "cart", item.id), { quantity: item.quantity });
  } catch (error) {
    console.error("Error updating item in cart: ", error);
  }
};

const removeCartItemFromFirestore = async (itemId, userId) => {
  try {
    await deleteDoc(doc(db, "users", userId, "cart", itemId));
  } catch (error) {
    console.error("Error removing item from cart: ", error);
  }
};

export const { setCartItems, addToCart, removeFromCart, updateQuantity, clearCart } = cartSlice.actions;
export default cartSlice.reducer;
