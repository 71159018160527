import React, { useState, useEffect } from "react";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { addDoc, collection, getDocs, doc, updateDoc, deleteDoc, serverTimestamp } from "firebase/firestore";
import { toast } from "react-toastify";
import { handleImageChange } from "../../Utility/utils";
import { db, storage } from "../../Config/Firebase/config";
import ConfirmationModal from "../../Utility/confirmModal";
import "./category.css";

const ModifyCategory = () => {
  const [title, setTitle] = useState("");
  const [file, setFile] = useState(null);
  const [available, setAvailable] = useState(true);
  const [categories, setCategories] = useState([]);
  const [editCategory, setEditCategory] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [deleteCategoryId, setDeleteCategoryId] = useState(null);

  useEffect(() => {
    const fetchCategories = async () => {
      const categoryCollectionRef = collection(db, "category");
      const data = await getDocs(categoryCollectionRef);
      const categoriesData = data.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
      setCategories(categoriesData);
    };
    fetchCategories();
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (title.trim() !== "" && file !== null) {
      const filesFolderRef = ref(storage, `categoryFiles/${file.name}`);
      try {
        await uploadBytes(filesFolderRef, file);
        const downloadURL = await getDownloadURL(filesFolderRef);
        await addDoc(collection(db, "category"), {
          title,
          image: downloadURL,
          available,
          createdAt: serverTimestamp(),
          updatedAt: serverTimestamp()
        });
        toast.success("Data uploaded successfully!");
        setTitle("");
        setFile(null);
        setAvailable(true);
        const updatedCategories = await getDocs(collection(db, "category"));
        setCategories(updatedCategories.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
      } catch (error) {
        toast.error("Something went wrong");
      }
    }
  };

  const handleEdit = async (event) => {
    event.preventDefault();
    try {
      const categoryRef = doc(db, "category", editCategory.id);
      const updates = {
        title,
        available,
        updatedAt: serverTimestamp(),
      };

      if (file) {
        const filesFolderRef = ref(storage, `categoryFiles/${file.name}`);
        await uploadBytes(filesFolderRef, file);
        const downloadURL = await getDownloadURL(filesFolderRef);
        updates.image = downloadURL;
      }

      await updateDoc(categoryRef, updates);
      setCategories((prevCategories) =>
        prevCategories.map((cat) => (cat.id === editCategory.id ? { ...cat, ...updates } : cat))
      );
      toast.success("Category updated successfully!");
      setEditCategory(null);
      setTitle("");
      setFile(null);
      setAvailable(true);
    } catch (error) {
      console.error("Error updating category: ", error);
      toast.error("Failed to update category");
    }
  };

  const handleDelete = async () => {
    try {
      const categoryRef = doc(db, "category", deleteCategoryId);
      await deleteDoc(categoryRef);
      setCategories((prevCategories) => prevCategories.filter((cat) => cat.id !== deleteCategoryId));
      toast.success("Category deleted successfully!");
      setIsModalOpen(false);
    } catch (error) {
      console.error("Error deleting category: ", error);
      toast.error("Failed to delete category");
    }
  };

  const onImageChange = (event) => {
    handleImageChange(event, setFile);
  };

  return (
    <div className="category-admin-container">
      <div className="category-admin-heading">
        <h3>LTS Category</h3>
      </div>
      <div className="category-admin-form">
        <form onSubmit={editCategory ? handleEdit : handleSubmit}>
          <div className="form-group">
            <label htmlFor="title">Title:</label>
            <input
              type="text"
              id="title"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="file">Choose Image:</label>
            <input
              type="file"
              id="file"
              accept="image/*"
              onChange={onImageChange}
            />
          </div>
          <div className="form-group">
            <label htmlFor="available">Available:</label>
            <select
              id="available"
              value={available}
              onChange={(e) => setAvailable(e.target.value === "true")}
              required
            >
              <option value="true">Yes</option>
              <option value="false">No</option>
            </select>
          </div>
          <button className="btn" type="submit">
            {editCategory ? "Update" : "Submit"}
          </button>
          {editCategory && (
            <button
              type="button"
              className="btn cancel-btn"
              onClick={() => {
                setEditCategory(null);
                setTitle("");
                setFile(null);
                setAvailable(true);
              }}
            >
              Cancel
            </button>
          )}
        </form>
      </div>
      <div className="category-list">
        <h3>Category List</h3>
        <ul>
          {categories.map((category) => (
            <li key={category.id}>
              <span>{category.title}</span>
              <button
                className="btn edit-btn"
                onClick={() => {
                  setEditCategory(category);
                  setTitle(category.title);
                  setAvailable(category.available);
                }}
              >
                Edit
              </button>
              <button
                className="btn delete-btn"
                onClick={() => {
                  setDeleteCategoryId(category.id);
                  setIsModalOpen(true);
                }}
              >
                Delete
              </button>
            </li>
          ))}
        </ul>
      </div>
      <ConfirmationModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onConfirm={handleDelete}
      />
    </div>
  );
};

export default ModifyCategory;
