import React from 'react';
import { useNavigate } from 'react-router-dom';
import './dashboard.css'; // Assuming you have a CSS file for styling

const AdminDashboard = () => {
  const navigate = useNavigate();

  const navigateTo = (path) => {
    navigate(path);
  };

  return (
    <div className="admin-dashboard">
      <h1>Admin Dashboard</h1>
      <div className="button-container">
        <button onClick={() => navigateTo('/qej8hh8jeq/orders')} className="nav-button">Orders</button>
        <button onClick={() => navigateTo('/qej8hh8jeq/reports')} className="nav-button">Reports</button>
        <button onClick={() => navigateTo('/qej8hh8jeq/messages')} className="nav-button">Messages</button>
        <button onClick={() => navigateTo('/qej8hh8jeq/products')} className="nav-button">Product</button>
        <button onClick={() => navigateTo('/qej8hh8jeq/categorys')} className="nav-button">Category</button>
        <button onClick={() => navigateTo('/qej8hh8jeq/general')} className="nav-button">General</button>
      </div>
    </div>
  );
};

export default AdminDashboard;
