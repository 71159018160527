import React from "react";
import UpdateFlag from "../../Admin/updateFlag/update";
import CarouselAdmin from "../../Admin/Carousel/carousel.jsx";
import "./admin.css";

const AdminContent = () => {
  return (
    <>
      <div className="admin-content">
        <CarouselAdmin/>
        <UpdateFlag/>
      </div>
    </>
  );
};

export default AdminContent;
