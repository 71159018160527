import React, { useState, useEffect } from "react";
import { doc, updateDoc, serverTimestamp, getDocs, collection } from "firebase/firestore";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { db, storage } from "../../Config/Firebase/config";
import { toast } from "react-toastify";
import { handleImageChange } from "../../Utility/utils";

const EditProduct = ({ product, setEditProduct, setProducts }) => {
  const [title, setTitle] = useState(product.title);
  const [file, setFile] = useState(null);
  const [price, setPrice] = useState(product.price);
  const [costPrice, setCostPrice] = useState(product.costPrice);
  const [available, setAvailable] = useState(product.available);
  const [category, setCategory] = useState(product.category);
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const categoryCollectionRef = collection(db, "category");
        const data = await getDocs(categoryCollectionRef);
        const categoriesData = data.docs.map((doc) => doc.data().title);
        setCategories(categoriesData);
      } catch (error) {
        console.error("Error fetching categories: ", error);
        toast.error("Failed to load categories");
      }
    };

    fetchCategories();
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const productRef = doc(db, "product", product.id);
      const updates = {
        title,
        price,
        costPrice,
        available: Boolean(available),
        category,
        updatedAt: serverTimestamp(),
      };

      if (file) {
        const filesFolderRef = ref(storage, `productImages/${file.name}`);
        await uploadBytes(filesFolderRef, file);
        const downloadURL = await getDownloadURL(filesFolderRef);
        updates.image = downloadURL;
      }

      await updateDoc(productRef, updates);
      setProducts((prevProducts) =>
        prevProducts.map((p) => (p.id === product.id ? { ...p, ...updates } : p))
      );
      toast.success("Product updated successfully!");
      setEditProduct(null);
    } catch (error) {
      console.error("Error updating product: ", error);
      toast.error("Failed to update product");
    }
  };

  const onImageChange = (event) => {
    handleImageChange(event, setFile);
  };

  return (
    <div className="edit-product-container">
      <div className="edit-product-heading">
        <h3>Edit Product</h3>
      </div>
      <div className="edit-product-form">
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="title">Title:</label>
            <input
              type="text"
              id="title"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="price">Price:</label>
            <input
              type="string"
              id="price"
              value={price}
              onChange={(e) => setPrice(e.target.value)}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="costPrice">Cost Price:</label>
            <input
              type="string"
              id="costPrice"
              value={costPrice}
              onChange={(e) => setCostPrice(e.target.value)}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="available">Available:</label>
            <select
              id="available"
              value={available}
              onChange={(e) => setAvailable(e.target.value === "true")}
              required
            >
              <option value="true">Yes</option>
              <option value="false">No</option>
            </select>
          </div>
          <div className="form-group">
            <label htmlFor="category">Category:</label>
            <select
              id="category"
              value={category}
              onChange={(e) => setCategory(e.target.value)}
              required
            >
              <option value="" disabled>Select category</option>
              {categories.map((cat, index) => (
                <option key={index} value={cat}>{cat}</option>
              ))}
            </select>
          </div>
          <div className="form-group">
            <label htmlFor="file">Choose Image (optional):</label>
            <input
              type="file"
              id="file"
              accept="image/*"
              onChange={onImageChange}
            />
          </div>
          <button className="btn" type="submit">
            Update
          </button>
          <button
            type="button"
            className="btn cancel-btn"
            onClick={() => setEditProduct(null)}
          >
            Cancel
          </button>
        </form>
      </div>
    </div>
  );
};

export default EditProduct;
