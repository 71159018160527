import { createContext, useContext, useEffect, useState } from "react";
import { onAuthStateChanged, signInWithPopup, signOut } from "firebase/auth";
import { auth, googleAuthProvider } from "../Config/Firebase/config";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../Config/Firebase/config";
import { useDispatch } from "react-redux";
import { setCartItems } from "../Components/Cart/cartSlice"; // new import for setting cart items

const UserAuthContext = createContext();

export function UserAuthContextProvider({ children }) {
  const [user, setUser] = useState(null);
  const dispatch = useDispatch();

  const fetchCartData = async (userId) => {
    const cartRef = collection(db, "users", userId, "cart");
    const cartSnapshot = await getDocs(cartRef);
    const cartItems = cartSnapshot.docs.map((doc) => doc.data());
    dispatch(setCartItems(cartItems)); // Dispatching the action to set cart items
  };

  const googleSignIn = async () => {
    if (!navigator.onLine) {
      throw new Error("no_internet_connection");
    }
    try {
      const result = await signInWithPopup(auth, googleAuthProvider);
      setUser(result.user);
      return result.user; // Return the signed-in user
    } catch (error) {
      console.error("Error during sign-in: ", error);
      throw error;
    }
  };

  const logOut = async () => {
    try {
      await signOut(auth);
      dispatch(setCartItems([])); // Reset cart items on logout
    } catch (error) {
      console.error("Error during sign-out: ", error);
    }
  };

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
      if (currentUser) {
        fetchCartData(currentUser.uid); // Fetch cart data for the logged-in user
      } else {
        dispatch(setCartItems([])); // Clear cart if no user is logged in
      }
    });
    return () => {
      unsubscribe();
    };
  }, [dispatch]);

  return (
    <UserAuthContext.Provider value={{ user, googleSignIn, logOut }}>
      {children}
    </UserAuthContext.Provider>
  );
}

export function useUserAuth() {
  return useContext(UserAuthContext);
}
