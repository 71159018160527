import React, { useEffect, useState }  from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import {Provider} from 'react-redux';
import store from "./Store/store.js";
import "./GlobalCSS/index.css";
import "./GlobalCSS/common.css";
import "./GlobalCSS/responsive.css";
import ErrorPage from './Components/ErrorPage/errorPage';
import AdminContent from './Containers/Admin/admin';
import ProtectedRoute from './Admin/ProtectedRoute';
import { UserAuthContextProvider} from './context/userAuthContext';
import { Slide, ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Header from './Components/Header/header';
import Theme from './Components/Theme/theme';
import Messages from './Admin/Contact/messages';
import FacebookMsg from './Components/FacebookMsg/facebookMsg';
import { collection, getDocs } from 'firebase/firestore';
import { db } from './Config/Firebase/config';
import Home from './Containers/Client/home';
import ModifyProduct from './Admin/Product/modifyProduct';
import ModifyCategory from './Admin/Category/modifyCategory';
import Contact from './Components/Contact/contact';
import CategoryPage from './Containers/Client/category';
import CategoryProducts from './Components/Product/categoryProduct';
import Cart from './Components/Cart/cart.jsx';
import Checkout from './Components/Checkout/checkout.jsx';
import AdminOrders from './Admin/Order/order.jsx';
import ReportPage from './Admin/Report/report.jsx';
import AdminDashboard from './Admin/dashboard/dashboard.jsx';
import OrderPage from './Components/Order/order.jsx';
import ProfilePage from './Components/Profile/profile.jsx';

function App() {

  const [day, setDay] = useState(false);

  const changeMode = () => {
    setDay((prevDay) => !prevDay);
    document.body.classList.toggle("dark");
  }

   useEffect(() => {
  const CACHE_KEY = "updateCache";
  const updateCollectionRef = collection(db, "update");

  const getUpdatedAtData = async () => {
    try {
      const data = await getDocs(updateCollectionRef);
      const filteredData = data.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }));
      const recentUpdate = {
        data: filteredData[0].updatedAt
      };

      const cached = localStorage.getItem(CACHE_KEY);

      if (cached) {
        const { data: cachedData } = JSON.parse(cached);
        const recentUpdateTimestamp = recentUpdate.data.seconds * 1000 + recentUpdate.data.nanoseconds / 1e6;
        const cachedTimestamp = cachedData.seconds * 1000 + cachedData.nanoseconds / 1e6;

        if (recentUpdateTimestamp > cachedTimestamp) {
          localStorage.clear();
        } else {
          toast.info("You are Viewing the Most Recent Version of the Website");
        }
      } else {
        localStorage.setItem(CACHE_KEY, JSON.stringify(recentUpdate));
      }
    } catch (error) {
      toast.error("Could not Load Update Flag");
    }
  };

  getUpdatedAtData();
}, []);


  return (
    <Provider store={store}>
      <UserAuthContextProvider>
        <BrowserRouter>
          <Header day={day} changeMode={changeMode}/>
          <Theme day={day} changeMode={changeMode}/>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/category" element={<CategoryPage />} />
            <Route path="/category/:category" element={<CategoryProducts />} />
            <Route path="/cart" element={<Cart />} />
            <Route path="/checkout" element={<Checkout/>} />
            <Route path="/orders" element ={<OrderPage/>} />
            <Route path="/profile" element = {<ProfilePage/>} />
             <Route path="/qej8hh8jeq/" element={<ProtectedRoute><AdminDashboard/></ProtectedRoute>} />
            <Route path="/qej8hh8jeq/general" element={<ProtectedRoute><AdminContent/></ProtectedRoute>} />
            <Route path="/qej8hh8jeq/messages" element={<ProtectedRoute><Messages/></ProtectedRoute>} />
            <Route path="/qej8hh8jeq/products" element={<ProtectedRoute><ModifyProduct/></ProtectedRoute>} />
            <Route path="/qej8hh8jeq/categorys" element={<ProtectedRoute><ModifyCategory/></ProtectedRoute>} />
            <Route path="/qej8hh8jeq/orders" element={<ProtectedRoute><AdminOrders/></ProtectedRoute>} />
            <Route path="/qej8hh8jeq/reports" element={<ProtectedRoute><ReportPage/></ProtectedRoute>} />
            <Route path="*" element={<ErrorPage />} />
          </Routes>
        </BrowserRouter>
        <ToastContainer
          position="bottom-center"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="colored"
          transition={Slide}
        />
        <FacebookMsg />
      </UserAuthContextProvider>
    </Provider>
  );
}

export default App;