import React, { useState, useEffect } from "react";
import { db } from "../../Config/Firebase/config.js";
import { collection, getDocs } from "firebase/firestore";
import './report.css';

const ReportPage = () => {
  const [orders, setOrders] = useState([]);

  useEffect(() => {
    fetchOrders();
  }, []);

  const fetchOrders = async () => {
    try {
      const querySnapshot = await getDocs(collection(db, "orders"));
      const ordersData = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      // Filter orders to include only those with status "delivered"
      const deliveredOrders = ordersData.filter(order => order.status === "delivered");
      setOrders(deliveredOrders);
    } catch (error) {
      console.error("Failed to fetch orders", error);
    }
  };

  const calculateTotals = () => {
    let totalQuantity = 0;
    let totalPrice = 0;
    let totalCostPrice = 0;

    orders.forEach(order => {
      order.items.forEach(item => {
        totalQuantity += item.quantity;
        totalPrice += item.price * item.quantity;
        totalCostPrice += item.costPrice * item.quantity;
      });
    });

    return {
      totalQuantity,
      totalPrice,
      totalCostPrice,
      netProfit: totalPrice - totalCostPrice,
    };
  };

  const { totalQuantity, totalPrice, totalCostPrice, netProfit } = calculateTotals();

  return (
    <section className="report-page section" id="report-page">
      <div className="container">
        <div className="section-title padd-15">
          <h2>Delivered Orders Report</h2>
        </div>
        <div className="report-summary">
          <div className="summary-item">
            <h3>Total Quantity</h3>
            <p>{totalQuantity}</p>
          </div>
          <div className="summary-item">
            <h3>Total Price</h3>
            <p>Rs {totalPrice.toFixed(2)}</p>
          </div>
          <div className="summary-item">
            <h3>Total Cost Price</h3>
            <p>Rs {totalCostPrice.toFixed(2)}</p>
          </div>
          <div className="summary-item">
            <h3>Net Profit</h3>
            <p>Rs {netProfit.toFixed(2)}</p>
          </div>
        </div>
        <div className="orders-list">
          {orders.map(order => {
            const orderTotalPrice = order.items.reduce((sum, item) => sum + item.price * item.quantity, 0);
            const orderTotalCostPrice = order.items.reduce((sum, item) => sum + item.costPrice * item.quantity, 0);
            const orderNetProfit = orderTotalPrice - orderTotalCostPrice;

            return (
              <div className="order-card" key={order.id}>
                <h4>Order ID: {order.orderId}</h4>
                <p>Total Quantity: {order.items.reduce((sum, item) => sum + item.quantity, 0)}</p>
                <p>Total Price: Rs {orderTotalPrice.toFixed(2)}</p>
                <p>Total Cost Price: Rs {orderTotalCostPrice.toFixed(2)}</p>
                <p>Net Profit: Rs {orderNetProfit.toFixed(2)}</p>
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
};

export default ReportPage;
