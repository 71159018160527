import React from "react";
import Category from "../../Components/Category/category";

const CategoryPage = () => {
  return (
    <>
      <div className="main-content">
        <Category />
      </div>
    </>
  );
};

export default CategoryPage;
