import React, { useState, useEffect } from "react";
import { db } from "../../Config/Firebase/config.js";
import { collection, getDocs, updateDoc, deleteDoc, doc } from "firebase/firestore";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import "./order.css";

const AdminOrders = () => {
  const [orders, setOrders] = useState([]);
  const [statusFilter, setStatusFilter] = useState("pending");

  useEffect(() => {
    fetchOrders();
  }, []);

  const fetchOrders = async () => {
    try {
      const querySnapshot = await getDocs(collection(db, "orders"));
      const ordersData = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      // Sort orders by orderDate in descending order
      ordersData.sort((a, b) => b.orderDate.seconds - a.orderDate.seconds);
      setOrders(ordersData);
    } catch (error) {
      toast.error("Failed to fetch orders");
    }
  };

  const handleStatusChange = async (orderId, newStatus) => {
    try {
      const orderRef = doc(db, "orders", orderId);
      await updateDoc(orderRef, { status: newStatus });
      fetchOrders();
      toast.success("Order status updated successfully");
    } catch (error) {
      toast.error("Failed to update order status");
    }
  };

  const handleDeleteOrder = async (orderId) => {
    if (window.confirm("Are you sure you want to delete this order?")) {
      try {
        const orderRef = doc(db, "orders", orderId);
        await deleteDoc(orderRef);
        fetchOrders();
        toast.success("Order deleted successfully");
      } catch (error) {
        toast.error("Failed to delete order");
      }
    }
  };

  const filteredOrders = statusFilter === "pending"
    ? orders.filter(order => order.status === "pending")
    : orders.filter(order => order.status === statusFilter);

  const formatDate = (seconds, nanoseconds) => {
    const date = new Date(seconds * 1000 + nanoseconds / 1000000);
    return date.toLocaleString();
  };

  const getOrderCountByStatus = (status) => {
        return orders.filter(order => order.status === status).length;
    };


  return (
    <section className="admin-orders section" id="admin-orders">
      <ToastContainer />
      <div className="container">
        <div className="row">
          <div className="section-title padd-15">
            <h2>Admin Orders</h2>
          </div>
        </div>
        <div className="row status-buttons">
                    {["pending", "processed", "packed", "on the way", "delivered"].map(status => (
                        <button
                            key={status}
                            onClick={() => setStatusFilter(status)}
                            className={statusFilter === status ? "active" : ""}
                        >
                            {status.charAt(0).toUpperCase() + status.slice(1)} ({getOrderCountByStatus(status)})
                        </button>
                    ))}
                </div>
        <div className="row orders-list">
          {filteredOrders.map(order => (
            <div className="order-card" key={order.id}>
              <h4>Order ID: {order.orderId}</h4>
              <p>Name: {order.address.name}</p>
              <p>City: {order.address.city}</p>
              <p>Ward No: {order.address.wardNo}</p>
              <p>Address Detail: {order.address.addressDetail}</p>
              <p>Phone Number: {order.address.phoneNumber}</p>
              <p>Order Date: {formatDate(order.orderDate.seconds, order.orderDate.nanoseconds)}</p>
              <p>Total Amount: Rs {order.totalAmount}</p>
              <p>Status: {order.status}</p>
              <div className="items-list">
                {order.items.map(item => (
                  <div className="item-card" key={item.id}>
                    <img src={item.image} alt={item.title} />
                    <p>Title: {item.title}</p>
                    <p>Category: {item.category}</p>
                    <p>Quantity: {item.quantity}</p>
                    <p>Price: Rs {item.price}</p>
                    <p>Cost Price: Rs {item.costPrice}</p>
                    <p>Available: {item.available ? "Yes" : "No"}</p>
                  </div>
                ))}
              </div>
              <div className="order-actions">
                <button onClick={() => handleStatusChange(order.id, "processed")}>Processed</button>
                <button onClick={() => handleStatusChange(order.id, "packed")}>Packed</button>
                <button onClick={() => handleStatusChange(order.id, "on the way")}>On The Way</button>
                <button onClick={() => handleStatusChange(order.id, "delivered")}>Delivered</button>
                <button className="delete-btn" onClick={() => handleDeleteOrder(order.id)}>Delete</button>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default AdminOrders;
